import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["signUpEmailInput"];

  connect() {
    if (this.shouldFocusSignUp()) {
      this.signUpEmailInputTarget.focus();
    }
  }

  shouldFocusSignUp() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("sign_up") === 'true';
  }  
}