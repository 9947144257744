
import {enter, leave} from "el-transition";
import CommandPalette from "../lib/command_palette";
import Modal from "../lib/modal";

/**
 * sets an element's inner html and also creates a timeout that will remove the elements
 * inner html specified duration
 * @param {HTMLElement} element the target element
 * @param {String} html the html to put inside element
 * @param {Number} duration duration of timeout that removes inner html from element
 * @returns {Number} timeout id of newly created timeout
 */
export function setInnerHtmlWithTimeout(element, html, duration) {
  element.innerHTML = html;
  enter(element);

  return setTimeout(() => {
    leave(element).then(() => {
      element.innerHTML = "";
    });
  }, duration);
}


/**
 * Brings focus back to the DOM. Used to return focus to the main window
 * element so events can be listened for (eg CMD+K to open command palette)
 * after focus is stolen from an iframe. (eg vimeo player)
 */
export function focusWindow() {
  window.focus();
}

/**
 * Returns true if an alternate UI context is show. (eg a modal or the cmd palette)
 */
export function alternateUiShown() {
  return (
    new CommandPalette.State().shown ||
    new Modal.State().shown
  );
}

/**
 * Returns the height in pixels of the sticky part of the nav
 */

export function getStickyNavHeight() {
  if (document.body.getAttribute("data-platform") == "native")
    return 0;

  const nav = document.getElementById("sticky-nav");

  if (!nav) {
    console.error("attempted to get the height of the sticky nav but couldn't find an element with id 'stick-nav'");
    return 0;
  }

  return nav.getBoundingClientRect().height;
}

/**
 * Simplified version of the Rails helper distance_of_time_in_words
 */
export function timeAgoInWords(from_time) {
  const to_time = Date.now();
  let distance_in_seconds = Math.round(Math.abs(to_time - from_time) / 1000);

  if (0 <= distance_in_seconds && distance_in_seconds <= 9) {
    return "just now";
  } else if (distance_in_seconds <= 59) {
    return "less than 1 minute ago";
  } else if (distance_in_seconds <= 119) {
    return "1 minute ago";
  } else {
    return "more than 1 minute ago";
  }
}

/**
 * Sets the browser location to root
 */
export function redirectToRoot() {
  window.location.replace("https://app.getmxu.com");
}

/**
 * Returns true if the current view is a turbo preview. IE a cached page from a 
 * previous Turbo visit is being shown while HTML is being requested from the
 * server.
 */
export function isTurboPreview() {
  return document.getRootNode().children[0].getAttribute("data-turbo-preview") != null;
}

export function isProduction() {
  return process.env.NODE_ENV == "production" &&
    ["app.getmxu.com", "getmxu.com"].includes(window.location.hostname);
}

export function isTurboNativeApp() {
  return document.body.getAttribute("data-platform") == "native";
}

export function setContext(newContext) {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (newContext)
    urlSearchParams.set("context", newContext);
  else
    urlSearchParams.delete("context");

  const newUrl = `${window.location.origin}${window.location.pathname}?${urlSearchParams.toString()}`;
  window.history.pushState({}, "", newUrl);

  const event = new Event("mxucontextchange", {detail: newContext});
  document.body.dispatchEvent(event);
}

/**
 * Returns the current context string according to the window's URL
 */
export function getCurrentContext() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const contextString = urlSearchParams.get("context");

  return contextString;
}

export function getHubSpotTrackingCode() {
  return document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, "$1");
}

export function getFacebookBrowserCookie() {
  return document.cookie.replace(/(?:(?:^|.*;\s*)_fbp\s*=\s*([^;]*).*$)|^.*$/, "$1");
}

export function getFacebookClickID() {
  const fbc = document.cookie.replace(/(?:(?:^|.*;\s*)_fbc\s*=\s*([^;]*).*$)|^.*$/, "$1");
  return fbc ? fbc : new URL(window.location.href).searchParams.get("fbclid");
}

export function getReferrer() {
  return document.referrer;
}

export function scrollToTopOfWindow() {
  window.scrollTo({top: 0, behavior: "smooth"});
}

export function disableScroll() {
  // Capture the current scroll position
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  
  // Store the scroll position in a data attribute
  document.body.setAttribute("data-scroll-top", scrollTop);

  // Disable scrolling and fix the body position
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollTop}px`;
  document.body.style.width = "100%";

  const nav = document.getElementById("sticky-nav");

  // Turbo native won't have nav
  if (nav) {
    nav.style.position = "fixed";
    nav.style.width = "100%";
  }
}

export function enableScroll() {
  // Retrieve the scroll position from the data attribute
  const scrollTop = parseInt(document.body.getAttribute("data-scroll-top"), 10);
  
  // Temporarily disable scroll restoration
  document.documentElement.style.scrollBehavior = "auto";

  // Re-enable scrolling
  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.top = "";
  document.body.style.width = "";
  
  // scroll back to where we were
  window.scrollTo(0, scrollTop);

  setTimeout(() => {
    document.documentElement.style.scrollBehavior = "";
  }, 0);
  
  // rm attr
  document.body.removeAttribute("data-scroll-top");

  const nav = document.getElementById("sticky-nav");

  // Turbo native won't have nav
  if (nav) {
    nav.style.position = "sticky";
    nav.style.width = "auto";
  }
}

export function isScrolled() {
  return window.scrollY > 0 || document.body.getAttribute("data-open-slide-over")?.trim()?.length > 0;
}

export function getEagerSlideOverId() {
  const url = new URL(window.location.href); 
  return url.searchParams.get("eagerSlideOverId");
}
