import {Controller} from "@hotwired/stimulus";
import Platform from "../lib/platform";
import CommandPalette from "../lib/command_palette";
import Interactions from "../lib/interactions";
import * as Util from "../lib/util";

const upsellObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    const element = entry.target;

    if (entry.isIntersecting) {
      const payload = {
        source: element.getAttribute("data-interaction-source"),
        name: element.getAttribute("data-interaction-name"),
        location: element.getAttribute("data-interaction-location"),
        detail: element.getAttribute("data-interaction-detail"),
        featureName: element.getAttribute("data-interaction-feature"),
      };

      Interactions.createButtonInteraction(payload);
    }
  });
}, {
  threshold: 0.5 // Trigger when 50% of the element is visible
});

export default class extends Controller {

  static targets = [
    "showOnApple",
    "showOnWindows",
    "showOnOther",
    "sticky",
    "moveToTopOfDom",
    "inheritContext",
    "upsell"
  ];

  /**
   * lifecycle
   */

  connect() {
    /**
     * Listen for context changes
     */
    document.body.addEventListener("mxucontextchange", () => {
      this.updateContextInheritingElements();
    });

    this.updateContextInheritingElements();
  }

  showOnAppleTargetConnected(element) {
    const mac = !!element.getAttribute("data-mac");
    const iphone = !!element.getAttribute("data-iphone");
    const ipad = !!element.getAttribute("data-ipad");
    const options = {mac, iphone, ipad};

    if (Platform.isApple(options))
      element.classList.toggle("hidden", false);
  }

  showOnWindowsTargetConnected(element) {
    if (Platform.isWindows())
      element.classList.toggle("hidden", false);
  }

  showOnOtherTargetConnected(element) {
    if (Platform.isOther())
      element.classList.toggle("hidden", false);
  }

  redirectTargetConnected(element) {
    const href = element.getAttribute("data-href");

    if (!href)
      throw new Error("Found a redirect target with no data-href attribute.");

    element.remove();

    Turbo.visit(href, {action: "advance"});
  }

  stickyTargetConnected(element) {
    element.style.position = "sticky";
    element.style.top = Util.getStickyNavHeight() + "px";
  }

  moveToTopOfDomTargetConnected(element) {
    const applicationModalContainer = document.getElementById("application_modals");
    
    if (element.parentElement != applicationModalContainer) {
      applicationModalContainer.appendChild(element);
    }
  }

  inheritContextTargetConnected(element) {
    this.updateContextInheritingElement(
      element,
      Util.getCurrentContext()
    );
  }

  upsellTargetConnected(element) {
    upsellObserver.observe(element);
  }

  /**
   * actions
   */

  showCommandPalette(event) {
    CommandPalette.show();

    event.stopPropagation();
    event.preventDefault();
  }

  storeInteraction(event) {
    const button = event.currentTarget;
    const source = button.getAttribute("data-interaction-source");
    const location = button.getAttribute("data-interaction-location");
    const detail = button.getAttribute("data-interaction-detail");
    const featureName = button.getAttribute("data-feature-name");

    Interactions.createButtonInteraction({
      source,
      location,
      detail,
      featureName
    });
  }

  /**
   * helpers
   */

  updateContextInheritingElements() {
    this.inheritContextTargets.forEach(element => {
      this.updateContextInheritingElement(
        element,
        Util.getCurrentContext()
      );
    });
  }

  updateContextInheritingElement(element, context) {
    if (element.tagName == "FORM") {
      const form = element;
      const url = new URL(form.getAttribute("action"), window.location.href);

      if (context)
        url.searchParams.set("context", context);
      else
        url.searchParams.delete("context");

      const newAction = `${url.pathname}?${url.searchParams.toString()}`;

      form.setAttribute("action", newAction);
    } else if (element.tagName == "A") {
      const link = element;
      const url = new URL(link.getAttribute("href"), window.location.href);

      if (context)
        url.searchParams.set("context", context);
      else
        url.searchParams.delete("context");

      const newHref = `${url.pathname}?${url.searchParams.toString()}`;

      link.setAttribute("href", newHref);
    }
  }
}