import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";
import * as Util from "../lib/util";

export default class extends Controller {
  static targets = ["cornermenu", "trigger", "dropdowninbox", "bar", "mobileMenuSafeClick"];

  /**
   * lifecycle
   */
  connect() {
    // Hide corner avatar menu
    if (this.hasCornermenuTarget) {
      this.cornermenuTarget.classList.toggle("hidden", true);
    }
    
    this.data.set("cornerMenuDisplayed", "false");

    // Hide dropdown inbox
    if (this.hasDropdowninboxTarget) {
      this.dropdowninboxTarget.classList.toggle("hidden", true);
      this.data.set("dropdownInboxDisplayed", "false");
    }

    document.addEventListener("click", this.handleDocumentClick.bind(this));
  }

  /**
   * actions
   */
  toggleCornerMenu() {
    let cornerMenuDisplayed = this.isCornermenuDisplayed();

    if (cornerMenuDisplayed) {
      leave(this.cornermenuTarget)
        .then(() => {
          this.data.set("cornerMenuDisplayed", false);
        });
    } else {
      enter(this.cornermenuTarget)
        .then(() => {
          this.data.set("cornerMenuDisplayed", true);
        });
    }
  }

  keyHide(e) {
    let cornerMenuDisplayed = this.isCornermenuDisplayed();

    if (e.keyCode == 27 && cornerMenuDisplayed == true) {
      e.preventDefault();
      this.data.set("cornerMenuDisplayed", !cornerMenuDisplayed);
      this.cornermenuTarget.classList.toggle("hidden", cornerMenuDisplayed);
    }
  }

  toggleDropdownInbox() {
    let dropdownInboxDisplayed = this.isDropdownInboxDisplayed();

    if (dropdownInboxDisplayed) {
      leave(this.dropdowninboxTarget)
        .then(() => {
          this.data.set("dropdownInboxDisplayed", false);
        });
    } else {
      enter(this.dropdowninboxTarget)
        .then(() => {
          this.data.set("dropdownInboxDisplayed", true);
        });
    }
  }

  styleBar() {
    if (Util.isScrolled()) {
      this.barTarget.classList.toggle("bg-transparent", false);
      this.barTarget.classList.toggle("bg-concert-900", true);
      this.barTarget.classList.toggle("backdrop-blur-lg", true);
      this.barTarget.classList.toggle("border-b", true);
    } else {
      this.barTarget.classList.toggle("bg-transparent", true);
      this.barTarget.classList.toggle("bg-concert-900", false);
      this.barTarget.classList.toggle("backdrop-blur-lg", false);
      this.barTarget.classList.toggle("border-b", false);
    }
  }

  /**
   * helpers
   */
  handleDocumentClick(e) {
    if (this.isCornermenuDisplayed() && this.shouldCloseOnDocumentClick(e)) {
      this.toggleCornerMenu();
    } else if (this.isMobileMenuDisplayed() && this.shouldCloseOnDocumentClick(e)) {
      this.toggleMobileMenu();
    } else if (this.isDropdownInboxDisplayed() && this.shouldCloseOnDocumentClick(e)) {
      this.toggleDropdownInbox();
    }
  }

  shouldCloseOnDocumentClick(e) {
    const target = e.target;
    const eventPath = e.composedPath();

    if (this.isCornermenuDisplayed()) {
      return this.isCornermenuDisplayed() &&
        !target.closest("[data-action='click->nav#toggleCornerMenu']")  &&
        !eventPath.includes(this.cornermenuTarget);
    } else if (this.isMobileMenuDisplayed()) {
      return this.isMobileMenuDisplayed() &&
        !target.closest("[data-action='click->nav#toggleMobileMenu']")  &&
        !eventPath.includes(this.mobileMenuSafeClickTarget) &&
        !eventPath.includes(document.querySelector("nav[data-controller='menu']"));
    } else if (this.isDropdownInboxDisplayed()) {
      return this.isDropdownInboxDisplayed() &&
        !target.closest("[data-action='click->nav#toggleDropdownInbox']")  &&
        !eventPath.includes(this.dropdowninboxTarget);
    }

    return false;
  }

  isCornermenuDisplayed() {
    return this.data.get("cornerMenuDisplayed") === "true";
  }

  isMobileMenuDisplayed() {
    return this.data.get("mobileMenuDisplayed") === "true";
  }

  isDropdownInboxDisplayed() {
    return this.data.get("dropdownInboxDisplayed") === "true";
  }
}
