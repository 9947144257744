/**
 * Module that helps update notification records
 */

export type IndexResponse = {
  success: boolean;
  data?: string;
};

export type IndexParams = {
  filters: string,
  page: number
};

export async function index({filters, page}: IndexParams): Promise<IndexResponse> {
  const encoded = {
    filters: encodeURIComponent(filters),
    page: encodeURIComponent(page)
  };

  const url = `/playlists?filters=${encoded.filters}&page=${encoded.page}`;

  const res = await fetch(url, {
    method: "GET",
    headers: {"Accept": "text/vnd.turbo-stream.html"}
  });

  if (res.ok) {
    const data = await res.text();
    return {success: true, data: data};
  }

  throw new Error(`Unable to get playlist page ${url}. Server response: ${res.status} '${await res.text()}'`);
}
