/**
 * Client side behavior for slide overs
 * 
 * Note: you can set a slide over to automatically open on page navigation by
 * providing the slide over id in the url param eagerSlideOverId=some_slide_over_id
 */

import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";
import {isTurboPreview} from "../lib/util";
import * as Util from "../lib/util";

export default class extends Controller {
  static targets = ["button", "body", "forceCloseOnAppear"];

  /**
   * lifecycle
   */

  connect() {
    this.element.setAttribute("data-open-slide-over", "");

    const eagerSlideOverId = Util.getEagerSlideOverId();

    if (eagerSlideOverId && !isTurboPreview()) {
      this.openSlideOver(
        eagerSlideOverId, {
          animate: false,
          softFail: true
        }
      );
    }

    this.connected = true;
  }

  bodyTargetConnected(element) {
    /**
     * Need to check to see if we should open this slide over automatically due
     * to the eagerSlideOverId URL param. Useful if the slide over is in a lazy 
     * loaded turbo frame.
     */

    const eagerSlideOverId = Util.getEagerSlideOverId();
    const connectedSlideOverId = element.getAttribute("data-slide-over-content-id");

    if (eagerSlideOverId && eagerSlideOverId == connectedSlideOverId && !this.isSlideOverOpen())
      this.openSlideOver(
        eagerSlideOverId, {
          animate: false,
          softFail: false
        }
      );
  }

  forceCloseOnAppearTargetConnected(element) {
    const currentId = this.currentlyOpenSlideOverId();
    this.closeSlideOver(currentId);
  }

  /**
   * actions
   */

  handleDocumentClick(event) {
    if (!this.isSlideOverOpen())
      return;

    const currentId = this.currentlyOpenSlideOverId();
    const openElement = this.getSlideOverContentElement(currentId);

    /**
     * We don't want to close the slide over if the click event came from an input because when
     * Rails UJS creates an ad-hoc form to handle data-turbo-method=post (or other non-get verbs), it
     * will do so outside the slide over container even if the event came from an element that originated
     * INSIDE the container.
     */
    const targetIsInput = event.target.tagName === "INPUT";

    const shouldClose = !event.target.closest("[data-slide-over-target='button']") && // not a slide over button
      !event.target.closest("[data-modal-role=panel]") && // not in a modal
      !event.composedPath().includes(openElement) && // not in the current slide over
      !targetIsInput; // not an input element

    if (shouldClose) {
      this.closeSlideOver(currentId);
    }
  }

  handleDocumentKeyDown(event) {
    const shouldClose = event.key == "Escape" &&
      this.isSlideOverOpen();

    // We don't want to close the slide over if you're focused on a form field
    const formFieldIsFocused = document.activeElement.tagName == "INPUT" ||
      document.activeElement.tagName == "SELECT";

    if (shouldClose && !formFieldIsFocused) {
      event.preventDefault();
      
      const id = this.currentlyOpenSlideOverId();
      this.closeSlideOver(id);
    }
  }

  click(event) {
    const element = event.currentTarget;
    const slideOverId = element.getAttribute("data-slide-over-id");
    const action = element.getAttribute("data-slide-over-action");
    const isLink = event.target.closest("a");
    const isInput = event.target.closest("input");

    if (isLink || isInput) {
      // Clicking links or inputs (form submit) inside slide over buttons should not open the slide over.
      // - Think board card overview partial
      return;
    }

    if (slideOverId == null)
      throw new Error("Unable to open slide over, data-slide-over-id is not present on the target element");

    if (action == "open") {
      this.openSlideOver(slideOverId);
    } else if (action == "close") {
      this.closeSlideOver(slideOverId);
    } else {
      this.toggleSlideOver(slideOverId);
    }
  }

  /**
   * helpers
   */

  openSlideOver(id, {animate = true, softFail = false} = {}) {
    if (this.isSlideOverOpen())
      this.closeSlideOver(this.currentlyOpenSlideOverId());

    const element = this.getSlideOverContentElement(id);

    if (!element && softFail) {
      return;
    } else if (!element && !softFail) {
      throw new Error(`Unable to open slide over  ${id}: no element with matching id`);
    }

    this.element.setAttribute("data-open-slide-over", id);

    const highlightSelector = element.getAttribute("data-slide-over-highlight-selector");

    if (highlightSelector)
      this.highlightSelector(highlightSelector);

    if (animate) {
      enter(element);
    } else {
      element.classList.toggle("hidden", false);
    }

    this.showUnderlayFor(id);

    Util.disableScroll();
  }

  closeSlideOver(id, {softFail} = {softFail: true}) {
    const element = this.getSlideOverContentElement(id);

    if (element == null && softFail) {
      this.element.setAttribute("data-open-slide-over", "");
      Util.enableScroll();
      return;

    } else if (element == null && !softFail) {
      throw new Error(`Unable to close slide over  ${id}: no element with matching id`);
    }

    this.element.setAttribute("data-open-slide-over", "");

    const highlightSelector = element.getAttribute("data-slide-over-highlight-selector");

    if (highlightSelector)
      this.unhighlightSelector(highlightSelector);

    leave(element);
    this.hideUnderlayFor(id)

    Util.enableScroll();
  }

  toggleSlideOver(id) {
    const currentOpenId = this.currentlyOpenSlideOverId();

    if (currentOpenId == id) {
      this.closeSlideOver(id);
    } else {
      this.openSlideOver(id);
    }
  }

  currentlyOpenSlideOverId() {
    return this.element.getAttribute("data-open-slide-over");
  }

  isSlideOverOpen() {
    const openId = this.currentlyOpenSlideOverId();
    return openId != null && openId != "";
  }

  getSlideOverContentElement(id) {
    return document.querySelector(`[data-slide-over-content-id='${id}']`);
  }
  
  getSlideOverContentUnderlayElement(id) {
    return document.querySelector(`[data-slide-over-content-underlay='${id}']`);
  }

  highlightSelector(selector) {
    document.querySelectorAll(selector).forEach(selected => {
      selected.classList.toggle("ring-4", true);
      selected.classList.toggle("ring-action-600", true);
    });
  }

  unhighlightSelector(selector) {
    document.querySelectorAll(selector).forEach(selected => {
      selected.classList.toggle("ring-4", false);
      selected.classList.toggle("ring-action-600", false);
    });
  }

  showUnderlayFor(id) {
    const underlay = this.getSlideOverContentUnderlayElement(id);

    if (underlay) {
      underlay.classList.toggle("backdrop-blur-lg", true);
      underlay.classList.toggle("md:backdrop-blur-none", true);
      underlay.classList.toggle("pointer-events-auto", true);
      underlay.classList.toggle("md:pointer-events-none", true);

      underlay.classList.toggle("pointer-events-none", false);
    }
  }

  hideUnderlayFor(id) {
    const underlay = this.getSlideOverContentUnderlayElement(id);

    if (underlay) {
      underlay.classList.toggle("backdrop-blur-lg", false);
      underlay.classList.toggle("md:backdrop-blur-none", false);
      underlay.classList.toggle("pointer-events-auto", false);
      underlay.classList.toggle("md:pointer-events-none", false);

      underlay.classList.toggle("pointer-events-none", true);
    }
  }
}
