import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger"];

  static values = {
    visibleClass: String,
    observeOnce: Boolean
  };

  connect() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.element.classList.add(this.visibleClassValue); 
          if (this.observeOnceValue) {
            this.observer.unobserve(entry.target); 
          }
        } else {
          if (!this.observeOnceValue) {
            this.element.classList.remove(this.visibleClassValue);
          }
        }
      });
    }, {threshold: 0.1});

    this.observer.observe(this.triggerTarget);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
